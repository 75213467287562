<template>
    <div>
      <div class="row migrations-page">
        <div class="col-lg-12">
          <KTPortlet v-bind:title="$t('MENU.MIGRATIONS')">
            <template v-slot:toolbar>
              <b-button
                variant="primary"
                class="mr-4"
                @click="openCreateModal()"
                v-if="canAdd"
              >
                {{ $t("MIGRATE.NEW_MIGRATION") }}
              </b-button>
            </template>
            <template v-slot:body>
              <div class="row table-filter">
                <div class="col-sm-6">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="$t('COMMON.SEARCH')"
                    class="cus-searchBox"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :items="migrationTable"
                class="elevation-1"
                ref="migrationTable"
                :search="search"
                :footer-props="{
                  showFirstLastPage: true,
                }"
              >
                <template v-slot:item.action="{ item }">
                  <v-icon
                    medium
                    color="dark"
                    @click="moreDetails(item)"
                    v-b-tooltip.hover
                    :title="$t('COMMON.MORE_DETAILS')"
                  >
                    info
                  </v-icon>
                </template>
              </v-data-table>
              <!-- ----------migration modal------------- -->
              <b-modal
                id="newMigration"
                ref="newMigration"
                :title="$t('MIGRATE.LOCAL_MIGRATION')"
                size="lg"
                >
                <b-container fluid>
                  <b-row class="my-4">
                    <h3>{{ $t("COMMON.SOURCE") }}</h3>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="4">
                      <label>{{ $t("COMMON.SEL_DOMAIN") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <model-list-select :list="migrationForm.src.domains.data"
                        v-model="migrationForm.src.domains.selected"
                        option-value="domain"
                        option-text="domain"
                        @input="getUsersForSelectedDomain(migrationForm.src)"
                        :placeholder="$t('COMMON.SEL_DOMAIN')">
                      </model-list-select>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="4">
                      <label>{{ $t("COMMON.SEL_USER") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <model-list-select :list="migrationForm.src.users.data"
                        v-model="migrationForm.src.users.selected"
                        option-value="email"
                        option-text="email"
                        :placeholder="$t('COMMON.SEL_USER')">
                      </model-list-select>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <h3>{{ $t("COMMON.DESTINATION") }}</h3>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="4">
                      <label>{{ $t("COMMON.SEL_DOMAIN") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <model-list-select :list="migrationForm.dest.domains.data"
                        v-model="migrationForm.dest.domains.selected"
                        option-value="domain"
                        option-text="domain"
                        @input="getUsersForSelectedDomain(migrationForm.dest)"
                        :placeholder="$t('COMMON.SEL_DOMAIN')">
                      </model-list-select>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="4">
                      <label>{{ $t("COMMON.SEL_USER") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <model-list-select :list="migrationForm.dest.users.data"
                        v-model="migrationForm.dest.users.selected"
                        option-value="email"
                        option-text="email"
                        :placeholder="$t('COMMON.SEL_USER')">
                      </model-list-select>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.MIGRATE_TO_FOLDER") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="migrationForm.destFolder"
                        :placeholder="$t('COMMON.OPTIONAL')"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-container>
                <template v-slot:modal-footer>
                    <b-button
                    variant="outline-secondary"
                    v-on:click="confirmModalClose('newMigration')"
                    >
                    {{ $t("COMMON.CANCEL") }}
                    </b-button>
                    <b-button
                    variant="primary"
                    v-on:click="createMigration()"
                    >
                    {{ $t("MIGRATE.MIGRATE") }}
                    </b-button>
                </template>
              </b-modal>
              <!-- ----------end migration modal------------- -->
              <!-- ----------- more details modal -->
              <b-modal
                id="migrationDetails"
                :title="$t('MIGRATE.DETAILS')"
                ref="migrationDetails"
                size="lg"
                @hide="stopRefreshingMigrationDetails()"
              >
                <b-container fluid>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("COMMON.SOURCE") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.source_username }}@{{ migrationDetails.source_domain }}</b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("COMMON.DESTINATION") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.dest_username }}@{{ migrationDetails.dest_domain }}</b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.FOLDER") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.dest_folder ? migrationDetails.dest_folder : $t("MIGRATE.DEFAULT") }}</b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.COMPLETE") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.complete == 1 ? $t("COMMON.YES"):$t("COMMON.NO") }}</b>
                    </b-col>
                  </b-row>
                  <div v-if="migrationDetails.complete == 0">
                    <b-row class="py-2">
                      <b-col sm="4">
                        <label>{{ $t("MIGRATE.ETA") }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b>{{ migrationDetails.eta ? migrationDetails.eta+" " + $t("MIGRATE.SECONDS_LEFT") :"-" }}</b>
                      </b-col>
                    </b-row>
                    <b-row class="py-2">
                      <b-col sm="4">
                        <label>{{ $t("MIGRATE.STAGE") }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b>{{ migrationDetails.stage }}</b>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.START_TIME") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.start_migration_ts ? new Date(migrationDetails.start_migration_ts):"-" }}</b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.END_TIME") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.end_migration_ts ? new Date(migrationDetails.end_migration_ts):"-" }}</b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.EXIT_STATUS") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.exit_status }}</b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.EXIT_MESSAGE") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ migrationDetails.exit_message }}</b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="4">
                      <label>{{ $t("MIGRATE.PROGRESS") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b>{{ parseFloat(migrationDetails.progress ? migrationDetails.progress:0) }}% </b>
                    </b-col>
                  </b-row>
                  <b-row class="py-2">
                    <b-col sm="12">
                      <b-progress :value="migrationDetails.progress ? migrationDetails.progress:0" show-progress></b-progress>
                      <p>{{ $t("MIGRATE.REFRESHING") }}</p>
                    </b-col>
                  </b-row>

                </b-container>
                <template v-slot:modal-footer>
                  <b-button
                    variant="outline-secondary"
                    v-on:click="closeMigrationDetails()"
                  >
                    {{ $t("COMMON.CLOSE") }}
                  </b-button>
                </template>
              </b-modal>
              <!-- ----------delete confirm modal end------------- -->
            </template>
          </KTPortlet>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import KTPortlet from "@/views/partials/content/Portlet.vue";
  import { postRequest } from "@/api/request.js";
  
  export default {
    name: "migrations",
    components: {
      KTPortlet,
    },
    data: () => ({
      search: "",
      migrationTable: [],
      allDomains: [],
      migrationForm: {
        src: {
          domains: {
            data: [],
            selected: {},
          },
          users: {
            data: [],
            selected: {},
          },
        },
        dest: {
          domains: {
            data: [],
            selected: {},
          },
          users: {
            data: [],
            selected: {},
          },
        },
        destFolder: "",
      },
      migrationDetails: {
        source_username: "",
        source_domain: "",
        dest_username: "",
        dest_domain: "",
        dest_folder: "",
        progress: "",
        eta: "",
        stage: "",
        complete: "",
        start_migration_ts: "",
        end_migration_ts: "",
        exit_status: "",
        exit_message: "",
      },
      timer:0,
    }),
    computed: {
      headers: function () {
        return [
          { text: this.$t("COMMON.SOURCE"), value: "source" },
          { text: this.$t("COMMON.DESTINATION"), value: "destination" },
          { text: this.$t("MIGRATE.FOLDER"), value: "dest_folder"},
          { text: this.$t("MIGRATE.START_TIME"), value: "start_date"},
          { text: this.$t("MIGRATE.END_TIME"), value: "end_date"},
          { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
        ];
      },
      canAdd: function () {
        return this.hasPermission("users", 5);
      },
    },
    created() {
      this.getMigrationsList();
      postRequest({
        action: "getAllDomains",
        token: localStorage.id_token,
      }).then((res) => {
        if (res) {
          if (!res.returncode) {
            res.returndata = [{ domain: res.returndata }];
          }
          this.allDomains = res.returndata;
          this.migrationForm.src.domains.data = this.allDomains;
          this.migrationForm.dest.domains.data = this.allDomains;
        }
      });
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.MIGRATIONS") },
      ]);
    },
    beforeDestroy () {
      this.stopRefreshingMigrationDetails();
    },
    methods: {
      migrationFormReset() {
        this.migrationForm.src.domains.selected = "";
        this.migrationForm.src.users.selected = "";
        this.migrationForm.dest.domains.selected = "";
        this.migrationForm.dest.users.selected = "";
        this.migrationForm.destFolder = "";
        //this.addForm.domain.selected = this.addForm.domain.data[0].domain;
      },
      /*  -----modal close------- */
      confirmModalClose(modalItem) {
        this.$refs[modalItem].hide();
      },
      stopRefreshingMigrationDetails(){
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      },
      /* ---------get all migrations---------- */
      getMigrationsList() {
        this.migrationTable = [];
        postRequest({
          action: "get_migration_jobs",
          token: localStorage.id_token,
        }).then((res) => {
          if (res) {
            if (!res.returncode) {
              this.migrationTable = [];
            } else {
              res.returndata.forEach((el) => {
                el.source = el.source_username+"@"+el.source_domain;
                el.destination = el.dest_username+"@"+el.dest_domain;
                //el.start_date = new Date(el.start_migration_ts);
                //el.end_date = new Date(el.end_migration_ts);
                el.end_date = el.end_migration_ts ? new Date(el.end_migration_ts) : "-";
                el.start_date = el.start_migration_ts ? new Date(el.start_migration_ts) : "-";
                this.migrationTable.push(el);
              });
            }
          }
        });
      },
      getUsersForSelectedDomain(obj) {
        if (obj.domains.data.length > 0 && obj.domains.selected !== "") {
          const domain = obj.domains.selected;
          postRequest({
            action: "getAllUsersDomain",
            token: localStorage.id_token,
            domain: domain,
            offset: 0,
            limit: 0,
            sorting: 0,
            slicing: 0,
            getOTPAndToken: 0,
            showQuota: 0,
          }).then((res) => {
            let result = [];
            if (res.returncode) {
              res.returndata.forEach((el) => {
                result.push({
                  email: el.email
                });
              });
            } else {
              result = [{ email: res.returndata }];
            }
            obj.users.data = result;
          });
        }
      },
  
      /* ------create migration---- */
      openCreateModal() {
        this.migrationFormReset();
        this.$refs["newMigration"].show();
      },
      createMigration(){
        const srcDomain = this.migrationForm.src.domains.selected;
        const srcUsername = this.migrationForm.src.users.selected.split("@")[0];
        const destDomain = this.migrationForm.dest.domains.selected;
        const destUsername = this.migrationForm.dest.users.selected.split("@")[0];
        const destFolder = this.migrationForm.destFolder;
        postRequest({
          action: "create_migration_job",
          token: localStorage.id_token,
          source_username: srcUsername,
          source_domain: srcDomain,
          dest_username: destUsername,
          dest_domain: destDomain,
          dest_folder: destFolder,
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
            this.$refs["newMigration"].hide();
            this.getMigrationsList();
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      },

      getMigrationDetails(item){
        postRequest({
          action: "get_migration_job_details",
          token: localStorage.id_token,
          migration_id: item.id,
        }).then((res) => {
          if (res.returncode) {
            this.migrationDetails = res.returndata;
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      },
      moreDetails(item) {
        this.stopRefreshingMigrationDetails();
        this.getMigrationDetails(item);
        this.$refs["migrationDetails"].show();

        //var self = this;
        this.timer = setInterval(() => this.getMigrationDetails(item), 10000);
      },

      closeMigrationDetails(){
        this.stopRefreshingMigrationDetails();
        this.confirmModalClose('migrationDetails');
      },
  
      /* ----notify---- */
      makeToastVariant(variant = null, msg) {
        let title = this.$t("COMMON.ERROR");
        if (variant == "success") {
          title = this.$t("COMMON.SUCCESS");
        }
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          toaster: "b-toaster-top-center",
          // autoHideDelay: 5000,
          solid: true,
        });
      },
    },
  };
  </script>
  